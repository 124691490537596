.app {
  text-align: center;
  justify-content: center;
  align-items: center;
}

.navbar-logo {
  width: 34px;
  height: 61px;
}

.main-background {
  background-color: #4c0409;
}

.bg-octohedz {
  background-color: #4d0408;
}
.bg-octohedz-l {
  background-color: #380404;
}
.bg-octohedz-d {
  background-color: #1f0002;
}
.bg-octo-btn {
  background-color: #e49e4f;
}
.bg-octo-btn:disabled:hover {
  background-color: #9a9a9a;
  color: #4d4d4d;
}
.bg-octo-btn:disabled {
  background-color: #9a9a9a;
  color: #4d4d4d;
  cursor: not-allowed;
}
.bg-octo-btn:hover {
  background-color: #c09463;
}
.bg-octo-pink {
  background-color: #d80e58;
}
.bg-octo-pink:hover {
  background-color: #b30c49;
}
.t-octo-pink {
  color: #d80e58;
}
.t-octohedz {
  color: #c6e893;
}

.btn-margin {
  margin-top: 30px;
}
.INKz-tiny {
  height: 32px;
  width: 32px;
}
.octo-logo-m {
  height: 246px;
  width: 137px;
}
.octo-logo-s {
  height: 61px;
  width: 34px;
}

/* The container */
.container-vx {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.container-vx input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
}

/* On mouse-over, add a grey background color */
.container-vx:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container-vx input:checked ~ .checkmark {
  background-color: #06141f;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container-vx input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container-vx .checkmark:after {
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.bg-rel {
  background-color: #ddd290;
  color: #12173D;
}

.octoz {
  margin-top: -30px;
  margin-bottom: 4px;
  width: 100%;
  padding: 3px;
  background-color: #91ff9a;
  color: #12173D;
  font-size: 14px;
  border-radius: 5px;
}
.octoz:hover {
  margin-top: -30px;
  margin-bottom: 4px;
  width: 100%;
  padding: 3px;
  background-color: #61f56d;
  color: #12173D;
  font-size: 14px;
  border-radius: 5px;
}
.octoz:disabled {
  background-color: #9a9a9a;
  color: #4d4d4d;
  cursor: not-allowed;
}